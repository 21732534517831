<style lang="postcss" scoped>
a,
a span,
a:hover,
a:hover span {
  text-decoration: none;
}

section:last-of-type {
  border-top-right-radius: 50px;
}

.landing-service {
  @apply my-6;
  box-shadow: 5px 20px 50px rgba(36, 148, 167, 0.15);
}

.tracking-spaceship {
  letter-spacing: 0.2rem;
}

.contact-label {
  @apply block;
  @apply text-left;
  @apply mb-2;
}

.contact-input:focus + label {
  color: var(--primary-color);
  @apply font-semibold;
}

.contact-input {
  @apply block;
  @apply mb-4;
  @apply w-full;
  @apply px-4;
  @apply py-2;
  @apply rounded;
  border: 1px solid var(--primary-color);
}

.contact-input:focus {
  @apply border-2;
  @apply outline-none;
}

#nous-contacter form {
  margin-top: -15rem;
}

@media (min-width: 768px) {
  #nous-contacter form {
    margin-top: -12rem;
  }
}
</style>

<template>
  <div class="landing">
    <header>
      <nav
        class="w-full flex items-center h-16 bg-white shadow-lg fixed top-0 right-0 left-0 z-10"
      >
        <a
          href="#home"
          class="ml-6 md:ml-0 w-1/4 text-primary text-center uppercase tracking-spaceship font-title font-bold text-xl hover:text-primary scrollactive-item"
          v-smooth-scroll
          >{{ name }}</a
        >
        <ul class="w-3/4 justify-end items-center hidden md:flex">
          <li class="text-center mr-6">
            <a href="#a-propos" class="scrollactive-item" v-smooth-scroll>À propos</a>
          </li>
          <li class="text-center mr-6">
            <a href="#services" class="scrollactive-item" v-smooth-scroll>Services</a>
          </li>
          <li class="text-center mr-6">
            <a href="#nous-rejoindre" class="scrollactive-item" v-smooth-scroll
              >Nous rejoindre</a
            >
          </li>
          <li class="text-center mr-12">
            <a href="#nous-contacter" class="scrollactive-item" v-smooth-scroll
              >Nous contacter</a
            >
          </li>
        </ul>
      </nav>
    </header>

    <p id="bandeau" class="w-full p-5 text-center mt-16 font-title text-white bg-primary text-2xl opacity-50">Bientôt disponible...</p>

    <section
      id="home"
      class="container my-8 lg:mb-16 mx-auto flex lg:flex-row flex-col align-center justify-center"
    >
      <div
        class="lg:w-2/3 m-4 mb-6 lg:m-6 lg:mt-0 flex flex-col justify-center"
      >
        <h1>Le plus chouette des gestionnaires de bibliothèque en ligne</h1>
        <p>
          OwlyBook, c’est un système de gestion de bibliothèque. Il permet de suivre virtuellement l’état de sa bibliothèque et d’y ajouter des livres, d’en supprimer, d’exprimer notre envie de les posséder ou d’indiquer leur lecture. Il s'adresse à <b>tous</b> les amateurs de livres&nbsp;: les collectionneurs, les gourmands, les picoreurs,&nbsp;…</p>
          <p>Notre souhait, avec cette application, est de rendre la gestion de votre bibliothèque aussi facile qu'agréable, quelle qu'en soit la taille. D'une édition précise à la collection d'œuvres complète, l'ajout de livres dans votre bibliothèque virtuelle se fait en un clic. Échanger autour de vos lectures n'en sera que plus aisé&nbsp;! Car oui, il s’agit également d'une communauté animée par la même passion&nbsp;: la lecture et tout ce qui l’entoure&nbsp;; afin que tout le monde puisse partager ses coups de cœur et en découvrir de nouveaux dans une atmosphère conviviale.
        </p>
      </div>
      <img
        src="../assets/img/landing_illu.png"
        alt="Image d'illustration de la landing Page"
        class="m-4 lg:w-1/3 object-contain"
      />
    </section>

    <section
      id="a-propos"
      class="container my-10 lg:my-20 mx-auto flex lg:flex-row flex-col-reverse align-center"
    >
      <img
        src="../assets/logo.png"
        alt="Owly, le logo du projet"
        class="m-4 lg:w-1/3 object-contain"
      />
      <div
        class="lg:w-2/3 m-4 mb-6 lg:m-6 lg:mt-0 flex flex-col justify-center"
      >
        <h2>Qui sommes-nous&nbsp;?</h2>
        <p>
          Nous formons un petit groupe d'addicts du livre, et n'arrivons pas à nous résoudre à utiliser un outil incomplet pour gérer notre bibliothèque. Nous avons chacun des exigences différentes, mais nous ne sommes pas prêts pour les compromis. Aussi, nous avons pris les devants&nbsp;: ne dit-on pas qu’on n’est jamais mieux servi que par soi-même&nbsp;? 
En attendant, si vous voulez nous connaître un peu mieux, vous pouvez venir nous parler directement <a href="https://discord.gg/eKw3qY86U2" target="_blank" >sur notre serveur Discord</a>.&nbsp;😉
        </p>
      </div>
    </section>

    <section id="services" class="container my-10 lg:my-20 mx-auto">
      <h2 class="m-2 mb-3 lg:m-6">
        Une application pensée pour ses utilisateurs
      </h2>
      <div
        class="flex items-center justify-center flex-col md:flex-row"
      >
        <div class="flex flex-col justify-center md:justify-right md:m-6">
          <LandingService
            title="Nous ne sommes pas suédois, mais nos bibliothèques valent le détour&nbsp;!"
            src="./img/billy.png"
            alt="Billy, le cousin suédois d'Owly"
          >
            Vous ne trouvez pas que gérer sa bibliothèque est fastidieux et chronophage sans la solution adaptée&nbsp;? Nous, si&nbsp;! Alors nous avons conçu l'outil dont nous avons tant rêvé… et nous sommes fiers de vous le présenter.&nbsp;😉
          </LandingService>

          <LandingService
            :title="title3"
            src="./img/owlytelephone.png"
            alt="Owly parvient parfaitement à se servir de l'application."
          >
            Nous aimons la lecture, mais pas vraiment celle des modes d’emploi. Vous pouvez donc profiter pleinement d'une application multi-plateforme à l'interface intuitive.
          </LandingService>
        </div>
        <div class="flex flex-col justify-center md:justify-left md:m-6">
          <LandingService
            class="primary-box-shadow"
            title="Des rats&nbsp;? Non, des hiboux de bibliothèque&nbsp;!"
            src="./img/owlyrat.png"
            alt="Owly et son ami le rat."
          >
            Prouvons au monde que la lecture n'est pas qu'un plaisir solitaire&nbsp;! Construisons une communauté bienveillante, amoureuse de la lecture et de la bonne humeur&nbsp;!
          </LandingService>

          <LandingService
            class="primary-box-shadow"
            title="Owly n’est pas un oiseau solitaire"
            src="./img/owlybranche.png"
            alt="Owly, Molly et Ginny sur une branche."
          >
            Il est impensable pour nous de rester dans notre coin à faire notre tambouille. Car même si nous sommes à l'origine de ce projet, il n'évoluera que grâce aux propositions et retours de chacun.
          </LandingService>

          <LandingService
            class="primary-box-shadow"
            title="Un choix à en faire tourner la tête d'Owly&nbsp;!"
            src="./img/owlyteteaenvers.png"
            alt="Owly ne sait plus où donner de la tête."
          >
            Parce que tout le monde est un peu pointilleux à sa manière, nous voulons vous offrir la possibilité de renseigner l’édition <b>exacte</b> que vous possédez ou désirez.
          </LandingService>
        </div>
      </div>
    </section>

    <section id="nous-rejoindre" class="container mb-80 my-10 lg:my-20 mx-auto text-center">
      <h2 class="text-center m-2 mb-3 lg:m-6">Apportez votre brindille au nid</h2>
      <p class="text-center m-2 mb-3 lg:m-6">
        Rejoignez notre communauté, devenez bêta-testeur, proposez des évolutions, découvrez le site avant tout le monde et utilisez-le dès sa sortie au maximum de son potentiel&nbsp;!
      </p>
      <p class="text-center m-2 mb-3 lg:m-6">
        Utilisez le chat ci-dessous ou, encore mieux, passez nous voir sur <a href="https://discord.gg/eKw3qY86U2" target="_blank" >notre Discord</a>&nbsp;!
      </p>

      <div class="flex">
        <div class="h-168 flex-grow -mr-1 hidden lg:block">
          <widgetbot
            server="736623641751453771"
            channel="784151908461445150"
            class="w-full h-full"
          ></widgetbot>
        </div>

        <iframe src="https://discord.com/widget?id=736623641751453771&theme=dark" class="mx-auto w-96 h-168" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
      </div>
    </section>

    <section
      id="nous-contacter"
      class="bg-primary pb-12 mt-20 md:mt-80"
      :style="
        'background-image: url(\'' + deco + '\'); background-repeat: no-repeat;'
      "
    >
      <div class="container mx-auto flex items-center flex-col-reverse md:flex-row">
        <p class="border-l-8 border-white rounded p-8 ml-2 md:ml-0 md:w-1/2">
          Vous souhaitez directement échanger avec nous, nous proposer des suggestions ou encore nous poser une question&nbsp;? N'hésitez pas à nous contacter, en utilisant le formulaire juste à côté ou directement à l'adresse <SafeMail class="text-white" :email="email" />.
        </p>
        <form
          action="#"
          method="post"
          @submit="checkForm"
          class="p-12 bg-white rounded-lg shadow-lg flex-grow flex flex-col"
        >
          <label for="nom" class="contact-label">Nom <span style="color: red">*</span></label>
          <input
            type="text"
            id="nom"
            class="contact-input"
            placeholder="Votre nom"
            name="name"
            v-model="formName"
          />

          <label for="email" class="contact-label">Adresse e-mail <span style="color: red">*</span></label>
          <input
            type="email"
            id="email"
            class="contact-input"
            placeholder="Votre adresse e-mail"
            name="email"
            v-model="formEmail"
          />

          <label for="message" class="contact-label">Message <span style="color: red">*</span></label>
          <textarea
            id="message"
            class="contact-input"
            rows="5"
            placeholder="Votre message"
            name="message"
            v-model="formMessage"
          ></textarea>

          <button
            type="submit"
            class="inline-block text-white bg-primary px-5 py-2 rounded-full"
          >
            Envoyer
            <fa icon="long-arrow-alt-right" type="fas" class="h-6 mx-auto inline text-white ml-2" />
          </button>

          <p v-if="errors.length">
            <b class="text-red-500">Veuillez corriger les erreurs&nbsp;:</b>
            <ul>
              <li v-for="error in errors" :key="error.id" class="text-red-500">{{ error }}</li>
            </ul>
          </p>

          <p v-if="success">
            <b class="text-green-500">Votre message a bien été envoyé&nbsp;!</b>
          </p>


        </form>
      </div>
    </section>

    <footer class="clear-both bg-primary pb-16 py-16">
      <div
        class="container mx-auto flex flex-col-reverse md:flex-row justify-between content-start"
      >
        <p class="text-center md:text-left">
          <a
            href="#"
            class="text-white hover:text-white uppercase tracking-spaceship font-title text-xl block mb-3 ml-3 md:ml-0"
            >{{ name }}</a
          >

          <span class="block font-light ml-3 md:ml-0">
            Développé par <a href="https://github.com/2alheure" class="text-white" target="_blank" >2alheure</a> & <a href="https://www.instagram.com/yamishadow_/" class="text-white" target="_blank" >YamiShadow</a>.
          </span>

          <span class="font-light ml-3 md:ml-0"
            >&copy;&nbsp;2020 - Tous droits réservés</span
          >
        </p>

        <p class="mb-6 md:mb-0 text-center">
          <span class="uppercase block text-white mb-3"
            >Suivez-nous sur nos réseaux</span
          >
          <a
            href="https://www.facebook.com/OwlyBookApp"
            class="w-12 h-12 inline-block py-3 text-center bg-white hover:bg-grey-200 rounded-full mx-3"
            target="_blank"
            title="Facebook"
          >
            <fa icon="facebook-f" type="fab" class="h-8 mx-auto relative -top-1 text-primary" />
          </a>
          <a
            href="https://twitter.com/owlybookapp"
            class="w-12 h-12 inline-block py-3 text-center bg-white hover:bg-grey-200 rounded-full mx-3"
            target="_blank"
            title="Twitter"
          >
            <fa icon="twitter" type="fab" class="h-8 mx-auto relative -top-0.5 text-primary" />
          </a>
          <a
            href="https://www.instagram.com/owlybookapp"
            class="w-12 h-12 inline-block py-3 text-center bg-white hover:bg-grey-200 rounded-full mx-3"
            target="_blank"
            title="Instagram"
          >
            <fa icon="instagram" type="fab" class="h-8 mx-auto relative -top-1 text-primary" />
          </a>
          <a
            href="https://www.linkedin.com/company/owlybook"
            class="w-12 h-12 inline-block py-3 text-center bg-white hover:bg-grey-200 rounded-full mx-3"
            target="_blank"
            title="LinkedIn"
          >
            <fa icon="linkedin-in" type="fab" class="h-8 mx-auto relative -top-1 text-primary" />
          </a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import deco from "@/assets/svg/deco.svg";

import LandingService from "@/components/other/LandingService.vue";
import SafeMail from "@/components/other/SafeMail.vue";

export default {
  name: "LandingPage",
  components: {
    LandingService,
    SafeMail,
  },
  data() {
    return {
      name: process.env.VUE_APP_NAME,
      deco: deco,
      email: "contact@owlybook.com",
      formName: "",
      formEmail: "",
      formMessage: "",
      errors: [],
      success: false,
      title3: 'C\'est simple comme "Houu houu&nbsp;!"',
    };
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.errors = [];
      this.success = false;

      if (this.formName && this.formEmail && this.formMessage) {
        this.axios
          .post("https://www.yamishadow.fr/contactOwlyBook.php", {
            name: this.formName,
            email: this.formEmail,
            message: this.formMessage,
          })
          .then((res) => {
            if (res.status == 200) {
              this.success = true;
              this.formName = "";
              this.formEmail = "";
              this.formMessage = "";
              this.errors = [];
            } else {
              this.errors = res.erreurs;
            }
          })
          .catch(() => {
            this.errors.push(
              "Le serveur de données semble inaccessible. Veuillez vérifier votre connexion et réessayer ultérieurement."
            );
          });
        return true;
      }

      if (!this.formName) {
        this.errors.push("Nom obligatoire.");
      }
      if (!this.formEmail) {
        this.errors.push("Email obligatoire.");
      }
      if (!this.formMessage) {
        this.errors.push("Message obligatoire.");
      }
    },
  },
  mounted() {
    let widgetScript = document.createElement("script");
    widgetScript.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/@widgetbot/html-embed"
    );
    document.head.appendChild(widgetScript);
  },
};
</script>