<template>
  <div class="landing-service bg-white shadow p-4 rounded w-80">
    <div class="flex justify-center mt-4">
      <img :src="src" :alt="alt" class="shadow w-36 h-36 rounded-full" />
    </div>
    <div class="mt-6 flex justify-between text-center">
      <div>
        <p class="text-gray-700 font-bold" v-html="title"></p>
        <p class="text-sm mt-2 text-gray-700 font-light">
          <slot>{{ desc }}</slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingService",
  props: {
    src: {
      type: String,
      default: "https://picsum.photos/200/300",
    },
    title: {
      type: String,
      default: "Un service particulièrement intéressant",
    },
    alt: {
      type: String,
      default: "Je suis une image d'illustration",
    },
    desc: {
      type: String,
      default:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident sed molestiae suscipit. Cumque amet omnis aspernatur unde quia dolorem, sit saepe accusantium, ipsum cum odio provident a eligendi veritatis reprehenderit.",
    },
  },
};
</script>
